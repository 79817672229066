@tailwind base;
@tailwind components;
@tailwind utilities;

html,
body,
#app {
    @apply h-full;
    @apply bg-cyan-900;
    @apply select-none;
    @apply text-sm;
}

svg {
    @apply inline;
}

button {
    @apply uppercase bg-zinc-200 hover:bg-cyan-400 active:bg-cyan-200 p-2 rounded border-zinc-400 border;
}

div {
    @apply uppercase;
}

.track {
    @apply bg-zinc-200 p-1 rounded inline-flex relative overflow-hidden border-zinc-400 border w-full;
}
